

header {
    position: absolute;
    width: 100vw;
}

#site-main {
    position: absolute;
    top: calc(var(--page-margin-top) + 8rem);
}

#side-menu {
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    width: var(--side-menu-width);
    transition: width 0.5s;
    --side-menu-width: 0rem;
    transform-style: preserve-3d;
}

#side-menu:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
}

#side-menu:hover {
    --side-menu-width: 25rem;
}

#the-aip-advantage {
    cursor: pointer;
}

/*#the-aip-advantage:hover + #side-menu {
    --side-menu-width: 25rem;
}*/

#side-menu:has(~ header #the-aip-advantage:hover) {
    --side-menu-width: 25rem;
}
